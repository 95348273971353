export const STATUS = {
  CONFIRMED: 'confirmed',
  RESERVED: 'reserved',
  INCOMPLETE: 'incomplete',
  FAILED: 'failed',
  PAID: 'paid',
  CANCELLED: 'cancelled',
  DEACTIVATED: 'deactivated',
  PARTIALLY_CANCELLED: 'partially_cancelled',
  PARTIALLY_CONFIRMED: 'partially_confirmed'
}

export const TICKETS_STATUS = {
  CANCELLED: 'cancelled'
}
