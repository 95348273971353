import 'core-js/stable'
import { IntlProvider } from 'react-intl'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-getcanonicallocales/polyfill'

import { client } from 'graphql/client'
import App from 'features/App/views/index'
import { ReactElement } from 'react'
const defaultLocale = 'en'

const render = (component: ReactElement): void => {
  const rootElement = document.getElementById('app-root')
  if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
      <ApolloProvider client={client}>
        <IntlProvider locale={defaultLocale} messages={{}} defaultLocale={defaultLocale}>
          {component}
        </IntlProvider>
      </ApolloProvider>
    )
  }
}

render(<App />)
