import { ApolloCache, gql } from '@apollo/client'
import { isEmpty } from 'lodash'

import { ANCILLARY_TYPE } from 'features/Bookings/components/AncillaryType/constants'
import { TICKET_INSURANCE_STATUS } from 'features/Bookings/components/TicketInsuranceStatus/constants'
import { isTicketCancelled } from 'resolvers/bookings'
import { has } from 'utils/array'
import { ITicket } from 'features/Bookings/types'
import { ITicketInsurance } from 'features/Ancillaries/types'
import { GTAncillaryStatus, GTAncillaryType, GTBookingAncillary, GTBookingTicket } from 'graphql/types.generated'

const CANCELLABLE_STATUS = [
  GTAncillaryStatus.Confirmed,
  GTAncillaryStatus.Reserved,
  GTAncillaryStatus.CreationFailed,
  GTAncillaryStatus.CancellationFailed,
  GTAncillaryStatus.UpdateFailed
]

const FAILED_STATUS = [
  GTAncillaryStatus.CreationFailed,
  GTAncillaryStatus.CancellationFailed,
  GTAncillaryStatus.UpdateFailed,
  GTAncillaryStatus.Failed
]

const IS_RESERVED_STATUS = [
  GTAncillaryStatus.Confirmed,
  GTAncillaryStatus.Reserved,
  GTAncillaryStatus.CreationFailed,
  GTAncillaryStatus.CancellationFailed,
  GTAncillaryStatus.UpdateFailed,
  GTAncillaryStatus.Expired
]

const APPLICABLE_STATUS = [GTAncillaryStatus.Cancelled]

export const canBeCancelled = (ticketInsuranceStatus: string): boolean => {
  return has(CANCELLABLE_STATUS, ticketInsuranceStatus)
}

export const canBeApplied = (ticketInsuranceStatus: string, insuranceIsPaid: boolean): boolean => {
  return has(APPLICABLE_STATUS, ticketInsuranceStatus) && !insuranceIsPaid
}

export const isAncillaryReserved = (ticketInsuranceStatus: string): boolean => {
  return !ticketInsuranceStatus || has(IS_RESERVED_STATUS, ticketInsuranceStatus)
}

export const getInsuranceByTicket = (ticket: GTBookingTicket): GTBookingAncillary | undefined => {
  const ancillaries = ticket.ancillaries || []
  return ancillaries.find(({ type }): TSFixMe => type === GTAncillaryType.Insurance)
}

export const getInsuranceTotalByTicket = (ticket: GTBookingTicket): number => {
  if (!isTicketCancelled(ticket)) {
    const insurance = getInsuranceByTicket(ticket)
    if (insurance && isAncillaryReserved(insurance.status)) {
      return insurance.total
    }
  }

  return 0
}

export const getTotalInsuranceByTickets = (tickets: GTBookingTicket[]): number => {
  let total = 0

  tickets.forEach((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const insurance = getInsuranceByTicket(ticket)

      if (insurance && isAncillaryReserved(insurance.status)) {
        total += insurance.total
      }
    }
  })

  return total
}

export const getTicketsToApplyInsurance = (tickets: ITicket[]): ITicket[] => {
  return tickets.filter((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }): TSFixMe => type === ANCILLARY_TYPE.INSURANCE)
      return !insurance || canBeApplied(insurance.status, false)
    }

    return false
  })
}

export const getTicketsToCancelInsurance = (tickets: ITicket[]): ITicket[] => {
  return tickets.filter((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }): TSFixMe => type === ANCILLARY_TYPE.INSURANCE)
      return insurance && canBeCancelled(insurance.status)
    }

    return false
  })
}

export const getStatusByTickets = (tickets: GTBookingTicket[]): string | null => {
  return tickets.reduce((result, ticket): TSFixMe => {
    if (isTicketCancelled(ticket)) {
      return result
    }

    const insurance = getInsuranceByTicket(ticket)

    if (!insurance || insurance.status === result) {
      return result
    }

    if (has(FAILED_STATUS, result ?? '') || has(FAILED_STATUS, insurance.status)) {
      return TICKET_INSURANCE_STATUS.FAILED
    }

    if (!result) {
      return insurance.status
    }

    if (result === TICKET_INSURANCE_STATUS.PARTIALLY_CANCELLED) {
      return result
    }

    if (
      (result === TICKET_INSURANCE_STATUS.CANCELLED && insurance.status !== result) ||
      (insurance.status === TICKET_INSURANCE_STATUS.CANCELLED && result !== insurance.status)
    ) {
      return TICKET_INSURANCE_STATUS.PARTIALLY_CANCELLED
    }

    if (
      (result === TICKET_INSURANCE_STATUS.CONFIRMED && insurance.status !== result) ||
      (insurance.status === TICKET_INSURANCE_STATUS.CONFIRMED && result !== insurance.status)
    ) {
      return TICKET_INSURANCE_STATUS.CONFIRMED
    }

    return result
  }, null)
}

const INSURANCE_FRAGMENT = gql`
  fragment TicketInsuranceStatus on TicketInsurance {
    insurance {
      status
      confirmedAt
      externalID
      certificateID
      insuranceCoverage {
        departureAt
        arrivalAt
        passenger {
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`

const BOOKING_TICKET_INSURANCE_FRAGMENT = gql`
  fragment BookingTicketInsurance on BookingTicket {
    ancillaries {
      paid
      type
      subtotal
      vat
      status
    }
  }
`

export const resolvers = {
  Mutation: {
    applyTicketInsurance(
      _root: unknown,
      { ticketInsuranceId }: { ticketInsuranceId: string },
      { cache }: { cache: ApolloCache<{ ticketInsuranceId: string }> }
    ): null {
      const fragment = cache.readFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT
      })

      const ticketInsurance = fragment as ITicketInsurance
      cache.writeFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT,
        data: {
          insurance: {
            ...ticketInsurance.insurance,
            status: TICKET_INSURANCE_STATUS.RESERVED
          },
          __typename: 'TicketInsurance'
        }
      })

      return null
    },
    applyTicketsInsurance(
      _root: unknown,
      { ticketIds }: { ticketIds: string[] },
      { cache }: { cache: ApolloCache<{ ticketIds: string[] }> }
    ): null {
      ticketIds.forEach((ticketId): TSFixMe => {
        let newAncillaries
        const fragment =
          cache.readFragment({
            id: cache.identify({
              __typename: 'BookingTicket',
              id: ticketId
            }),
            fragment: BOOKING_TICKET_INSURANCE_FRAGMENT
          }) ?? []

        const ticket = fragment as ITicket
        const ancillaries = ticket.ancillaries || []

        const insurance = ancillaries.find(({ type }): TSFixMe => type === ANCILLARY_TYPE.INSURANCE)

        if (!isEmpty(insurance)) {
          newAncillaries = ancillaries.map((ancillary): TSFixMe => {
            if (ancillary.type === ANCILLARY_TYPE.INSURANCE) {
              return {
                ...ancillary,
                status: TICKET_INSURANCE_STATUS.RESERVED
              }
            } else {
              return ancillary
            }
          })
        } else {
          newAncillaries = [
            ...ancillaries,
            {
              status: TICKET_INSURANCE_STATUS.RESERVED,
              subtotal: 0,
              vat: 0,
              type: ANCILLARY_TYPE.INSURANCE
            }
          ]
        }

        cache.writeFragment({
          id: cache.identify({
            __typename: 'BookingTicket',
            id: ticketId
          }),
          fragment: BOOKING_TICKET_INSURANCE_FRAGMENT,
          data: {
            ancillaries: newAncillaries,
            __typename: 'BookingTicket'
          }
        })
      })

      return null
    },
    cancelTicketInsurance(
      _root: unknown,
      { ticketInsuranceId }: { ticketInsuranceId: string },
      { cache }: { cache: ApolloCache<{ ticketInsuranceId: string }> }
    ): null {
      const fragment = cache.readFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT
      })

      const ticketInsurance = fragment as ITicketInsurance
      cache.writeFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT,
        data: {
          __typename: 'TicketInsurance',
          insurance: {
            ...ticketInsurance.insurance,
            status: TICKET_INSURANCE_STATUS.CANCELLED
          }
        }
      })

      return null
    },
    cancelTicketsInsurance(
      _root: unknown,
      { ticketIds, status }: { ticketIds: string[]; status: string },
      { cache }: { cache: ApolloCache<{ ticketIds: string[]; status: string }> }
    ): null {
      status = status || TICKET_INSURANCE_STATUS.CANCELLED

      ticketIds.forEach((ticketId): TSFixMe => {
        let newAncillaries
        const fragment =
          cache.readFragment({
            id: cache.identify({
              __typename: 'BookingTicket',
              id: ticketId
            }),
            fragment: BOOKING_TICKET_INSURANCE_FRAGMENT
          }) || []

        const ticket = fragment as ITicket
        const ancillaries = ticket.ancillaries || []

        const insurance = ancillaries.find(({ type }): TSFixMe => type === ANCILLARY_TYPE.INSURANCE)

        if (!isEmpty(insurance) && canBeCancelled(insurance.status)) {
          newAncillaries = ancillaries.map((ancillary): TSFixMe => {
            if (ancillary.type === ANCILLARY_TYPE.INSURANCE) {
              return {
                ...ancillary,
                status
              }
            } else {
              return ancillary
            }
          })

          cache.writeFragment({
            id: cache.identify({
              __typename: 'BookingTicket',
              id: ticketId
            }),
            fragment: BOOKING_TICKET_INSURANCE_FRAGMENT,
            data: {
              ancillaries: newAncillaries,
              __typename: 'BookingTicket'
            }
          })
        }
      })

      return null
    }
  }
}
