import { gql, InMemoryCache } from '@apollo/client'
import { flatten, isEmpty, max, camelCase } from 'lodash'
import { defineMessages } from 'react-intl'

import { ANCILLARY_TYPE } from 'features/Bookings/components/AncillaryType/constants'
import { STATUS, TICKETS_STATUS } from 'features/Bookings/components/Status/constants'
import {
  IPrevBookingsDashboard,
  IBookingsDashboardSubscription,
  TPassengerWithTickets,
  IMovedBooking
} from 'features/Bookings/types'
import {
  GTBooking,
  GTBookingsDashboardData,
  GTBookingsDashboardDataInfo,
  GTBookingsDashboardDataMethodInfo,
  GTBookingTicket,
  GTBookingTrip,
  GTPaymentTicket,
  GTBookingPayment,
  GTSupportInteraction,
  GTBookingRefund,
  GTBookingTripSegment
} from 'graphql/types.generated'
import {
  canBeApplied as canApplyInsuranceForTicket,
  canBeCancelled as canCancelInsuranceForTicket
} from 'resolvers/insurance'
import { has } from 'utils/array'

export const CHANGE_TRIP_BOOKING_SEAT = gql`
  mutation ChangeTripBookingSeat($tripId: ID!, $bookingId: ID!, $oldSeatId: ID!, $newSeatId: ID!, $segmentId: ID!) {
    changeTripBookingSeat(
      input: {
        id: $tripId
        booking: { id: $bookingId, oldSeatID: $oldSeatId, newSeatID: $newSeatId, segmentID: $segmentId }
      }
    )
  }
`

export const PAYMENT_METHOD = {
  CASH: 'cash',
  CARD: 'card',
  MIXED: 'mixed',
  COUPON: 'coupon',
  RESERVED: 'reserved',
  KREDIT: 'kredit'
}

export const PROCESSOR = {
  CONEKTA: 'conekta',
  CLIP: 'clip'
}

export const PAYMENT_STATUS = {
  PAID: 'paid',
  REFUNDED: 'refunded',
  PENDING: 'pending'
}

export const paymentStatusMessages = defineMessages({
  [PAYMENT_STATUS.PAID]: {
    id: 'dictionary.paid',
    defaultMessage: 'Paid'
  },
  [PAYMENT_STATUS.PENDING]: {
    id: 'dictionary.pending',
    defaultMessage: 'Pending'
  }
})

export const bookingHasReservation = (booking: GTBooking): boolean => {
  return (
    booking.status === STATUS.RESERVED ||
    booking.status === STATUS.CONFIRMED ||
    booking.status === STATUS.PARTIALLY_CONFIRMED
  )
}

export const bookingIsDeactivated = (booking: GTBooking): boolean => {
  return booking.status === STATUS.DEACTIVATED
}

export const isConfirmed = (booking: GTBooking): boolean => {
  return booking.status === STATUS.CONFIRMED || booking.status === STATUS.PARTIALLY_CONFIRMED
}

export const isReserved = (booking: GTBooking): boolean => {
  return booking.status === STATUS.RESERVED
}

export const isPartiallyConfirmed = (booking: GTBooking): boolean => {
  return booking.status === STATUS.PARTIALLY_CONFIRMED
}

export const bookingHasPaidStatus = (booking: GTBooking): boolean => {
  return booking.status === STATUS.PAID
}

export const isCancelled = (booking: GTBooking): boolean => {
  return booking.status === STATUS.CANCELLED
}

export const isTicketCancelled = (ticket: Pick<GTBookingTicket, 'status'>): boolean => {
  return ticket.status === TICKETS_STATUS.CANCELLED
}

export const getPaidTicketsByTrips = ({
  tickets,
  trips,
  payments
}: {
  tickets: GTBookingTicket[]
  trips: GTBookingTrip[]
  payments: GTBookingPayment[]
}): GTBookingTicket[] => {
  return tickets.filter((ticket): TSFixMe => {
    if (isTicketCancelled(ticket)) return false

    const ticketTrip = trips.find((trip): TSFixMe => trip.id === ticket.trip?.id)

    if (!isEmpty(ticketTrip)) {
      const ticketPayment = payments.find((payment): TSFixMe => {
        return (
          payment.status === PAYMENT_STATUS.PAID &&
          !isEmpty(payment.tickets.find(({ id }): TSFixMe => id === ticket.id))
        )
      })

      return !isEmpty(ticketPayment)
    }

    return false
  })
}

export const hasNotPaidReturnTrip = (booking: GTBooking): boolean => {
  return (
    booking.returnTrips.length > 0 &&
    isPartiallyConfirmed(booking) &&
    isEmpty(
      getPaidTicketsByTrips({
        tickets: booking.tickets,
        trips: booking.returnTrips,
        payments: booking.payments
      })
    )
  )
}

export const getUnpaidTicketsForPayment = (booking: GTBooking): GTBookingTicket[] | undefined => {
  if (isReserved(booking)) {
    return booking.tickets.filter((ticket): TSFixMe => !isTicketCancelled(ticket))
  } else if (hasNotPaidReturnTrip(booking)) {
    return booking.tickets.filter((ticket): TSFixMe => {
      if (isTicketCancelled(ticket)) return false

      const ticketTrip = booking.returnTrips.find((trip): TSFixMe => trip.id === ticket.trip?.id)

      return !isEmpty(ticketTrip)
    })
  }
}

export const getBookingAmountByTickets = ({
  tickets
}: {
  tickets: GTBookingTicket[]
}): { total: number; subtotal: number; vat: number; discount: number } => {
  let subtotal = 0
  let vat = 0
  let discount = 0

  tickets.forEach((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      subtotal += ticket.subtotal
      vat += ticket.vat
      discount += ticket.discount
    }
  })

  return {
    total: subtotal + vat - discount,
    subtotal,
    vat,
    discount
  }
}

export const findValidTicketsByTrip = (tickets: GTBookingTicket[], trip: GTBookingTrip): GTBookingTicket[] => {
  return tickets.filter((ticket): TSFixMe => ticket.trip?.id === trip.id && !isTicketCancelled(ticket))
}

export const findValidTicketsByTrips = (tickets: GTBookingTicket[], trips: GTBookingTrip[]): GTBookingTicket[] => {
  return flatten(
    trips.map((trip): TSFixMe => {
      return findValidTicketsByTrip(tickets, trip)
    })
  )
}

export const isSameTrip = (departureTrips: GTBookingTripSegment[], returnTrips: GTBookingTripSegment[]): boolean => {
  return departureTrips.every((departureTrip): TSFixMe => {
    return returnTrips.some((returnTrip): TSFixMe => {
      return departureTrip.id === returnTrip.id
    })
  })
}

const DEFAULT_NUMBERS_BY_STATUS = {
  online: {
    count: 0,
    amount: 0
  },
  offline: {
    count: 0,
    amount: 0
  }
}
export const getTotalDashboard = ({
  reserved,
  confirmed,
  partiallyConfirmed
}: {
  reserved: GTBookingsDashboardDataMethodInfo
  confirmed: GTBookingsDashboardDataMethodInfo
  partiallyConfirmed: GTBookingsDashboardDataMethodInfo
}): GTBookingsDashboardDataMethodInfo => {
  reserved = reserved || DEFAULT_NUMBERS_BY_STATUS
  confirmed = confirmed || DEFAULT_NUMBERS_BY_STATUS
  partiallyConfirmed = partiallyConfirmed || DEFAULT_NUMBERS_BY_STATUS

  return {
    online: {
      count: reserved.online.count + confirmed.online.count + partiallyConfirmed.online.count,
      amount: reserved.online.amount + confirmed.online.amount + partiallyConfirmed.online.amount
    },
    offline: {
      count: reserved.offline.count + confirmed.offline.count + partiallyConfirmed.offline.count,
      amount: reserved.offline.amount + confirmed.offline.amount + partiallyConfirmed.offline.amount
    }
  }
}

export const getTotalDashboardByStatus = ({
  online,
  offline
}: {
  online: GTBookingsDashboardDataInfo
  offline: GTBookingsDashboardDataInfo
}): GTBookingsDashboardDataInfo => {
  online = online || DEFAULT_NUMBERS_BY_STATUS.online
  offline = offline || DEFAULT_NUMBERS_BY_STATUS.offline

  return {
    count: offline.count + online.count,
    amount: offline.amount + online.amount
  }
}

export const getPassengersByTickets = (booking: Pick<IMovedBooking, 'tickets'>): TPassengerWithTickets[] => {
  return booking.tickets.reduce((result, ticket): TSFixMe => {
    if (isTicketCancelled(ticket)) return result

    const passengerWithTicket = result.find((passengerWithTickets): TSFixMe => {
      return (
        ticket.passenger?.firstName === passengerWithTickets.firstName &&
        ticket.passenger?.lastName === passengerWithTickets.lastName &&
        ticket.passenger?.typeV2.name === passengerWithTickets.typeV2.name
      )
    })

    if (!passengerWithTicket) {
      return [
        ...result,
        {
          ...ticket.passenger,
          tickets: [ticket]
        }
      ]
    } else {
      let ticketAssigned = false
      const passengersWithTickets = result.map((passengerWithTickets): TSFixMe => {
        const tripTicket = passengerWithTickets.tickets?.find(({ trip }): TSFixMe => trip?.id === ticket.trip?.id)
        const isPassengerTicket =
          ticket.passenger?.firstName === passengerWithTickets.firstName &&
          ticket.passenger?.lastName === passengerWithTickets.lastName &&
          ticket.passenger?.typeV2.name === passengerWithTickets.typeV2.name

        if (isPassengerTicket && !tripTicket && !ticketAssigned) {
          ticketAssigned = true

          return {
            ...passengerWithTickets,
            tickets: [...passengerWithTickets.tickets, ticket]
          }
        }

        return passengerWithTickets
      })

      if (!ticketAssigned) {
        return [
          ...passengersWithTickets,
          {
            ...ticket.passenger,
            tickets: [ticket]
          }
        ]
      }

      return passengersWithTickets
    }
  }, [] as TPassengerWithTickets[]) as TPassengerWithTickets[]
}

const getRandomId = (): string => {
  return Math.random().toString().replace('.', '')
}

const BOOKINGS_DASHBOARD_STATUS = [
  STATUS.CONFIRMED,
  STATUS.PARTIALLY_CONFIRMED,
  STATUS.RESERVED,
  STATUS.FAILED,
  STATUS.CANCELLED
]

export const updateBookingsDashboardWithSubscription = (
  prev: IPrevBookingsDashboard,
  { subscriptionData }: { subscriptionData: IBookingsDashboardSubscription }
): IPrevBookingsDashboard => {
  if (!subscriptionData.data) return prev

  const { bookingsDashboard } = subscriptionData.data

  let bookingsDashboardCopy = Object.assign({}, prev.bookingsDashboard)
  const now = new Date()
  const createdAt = new Date(bookingsDashboard.createdAt)
  const isBookingCreatedToday = now.getDate() === createdAt.getDate()
  const { status: previousStatus, amount: previousAmount } = bookingsDashboard.previous
  const previousStatusKey = camelCase(previousStatus) as Exclude<keyof GTBookingsDashboardData, '__typename'>
  if (has(BOOKINGS_DASHBOARD_STATUS, previousStatus)) {
    if (isBookingCreatedToday) {
      const todayNumbersBySaleChannel = prev.bookingsDashboard.day[previousStatusKey]?.[bookingsDashboard.saleChannel]
      const currentAmount = todayNumbersBySaleChannel?.amount || 0
      const currentCount = todayNumbersBySaleChannel?.count || 0
      const newValues = {
        amount: max([currentAmount - previousAmount, 0]),
        count: max([currentCount - 1, 0])
      }
      bookingsDashboardCopy = {
        ...bookingsDashboardCopy,
        day: {
          ...bookingsDashboardCopy.day,
          [previousStatusKey]: {
            ...bookingsDashboardCopy.day[previousStatusKey],
            [bookingsDashboard.saleChannel]: newValues,
            total: getTotalDashboardByStatus({
              ...bookingsDashboardCopy.day[previousStatusKey],
              [bookingsDashboard.saleChannel]: newValues
            })
          }
        }
      }
    }
    const currentMonthlyAmount =
      prev.bookingsDashboard.month[previousStatusKey][bookingsDashboard.saleChannel].amount || 0
    const currentMonthlyCount =
      prev.bookingsDashboard.month[previousStatusKey][bookingsDashboard.saleChannel].count || 0
    const newValues = {
      amount: max([currentMonthlyAmount - previousAmount, 0]),
      count: max([currentMonthlyCount - 1, 0])
    }
    bookingsDashboardCopy = {
      ...bookingsDashboardCopy,
      month: {
        ...bookingsDashboardCopy.month,
        [previousStatusKey]: {
          ...bookingsDashboardCopy.month[previousStatusKey],
          [bookingsDashboard.saleChannel]: newValues,
          total: getTotalDashboardByStatus({
            ...bookingsDashboardCopy.month[previousStatusKey],
            [bookingsDashboard.saleChannel]: newValues
          })
        }
      }
    }
  }

  const newStatusKey = camelCase(bookingsDashboard.status) as Exclude<keyof GTBookingsDashboardData, '__typename'>
  if (has(BOOKINGS_DASHBOARD_STATUS, bookingsDashboard.status)) {
    const newAmount = bookingsDashboard.amount

    if (isBookingCreatedToday) {
      const currentAmount = prev.bookingsDashboard.day[newStatusKey][bookingsDashboard.saleChannel].amount || 0
      const currentCount = prev.bookingsDashboard.day[newStatusKey][bookingsDashboard.saleChannel].count || 0
      const newValues = {
        amount: currentAmount + newAmount,
        count: currentCount + 1
      }
      bookingsDashboardCopy = {
        ...bookingsDashboardCopy,
        day: {
          ...bookingsDashboardCopy.day,
          [newStatusKey]: {
            ...bookingsDashboardCopy.day[newStatusKey],
            [bookingsDashboard.saleChannel]: newValues,
            total: getTotalDashboardByStatus({
              ...bookingsDashboardCopy.day[newStatusKey],
              [bookingsDashboard.saleChannel]: newValues
            })
          }
        }
      }
    }
    const currentMonthlyAmount = prev.bookingsDashboard.month[newStatusKey][bookingsDashboard.saleChannel].amount || 0
    const currentMonthlyCount = prev.bookingsDashboard.month[newStatusKey][bookingsDashboard.saleChannel].count || 0
    const newValues = {
      amount: currentMonthlyAmount + newAmount,
      count: currentMonthlyCount + 1
    }
    bookingsDashboardCopy = {
      ...bookingsDashboardCopy,
      month: {
        ...bookingsDashboardCopy.month,
        [newStatusKey]: {
          ...bookingsDashboardCopy.month[newStatusKey],
          [bookingsDashboard.saleChannel]: newValues,
          total: getTotalDashboardByStatus({
            ...bookingsDashboardCopy.month[newStatusKey],
            [bookingsDashboard.saleChannel]: newValues
          })
        }
      }
    }
  }

  bookingsDashboardCopy = {
    ...bookingsDashboardCopy,
    day: {
      ...bookingsDashboardCopy.day,
      total: getTotalDashboard(bookingsDashboardCopy.day),
      onlinePercent: getOnlinePercent(bookingsDashboardCopy.day),
      offlinePercent: getOfflinePercent(bookingsDashboardCopy.day)
    },
    month: {
      ...bookingsDashboardCopy.month,
      total: getTotalDashboard(bookingsDashboardCopy.month),
      onlinePercent: getOnlinePercent(bookingsDashboardCopy.month),
      offlinePercent: getOfflinePercent(bookingsDashboardCopy.month)
    }
  }

  return Object.assign({}, prev, {
    bookingsDashboard: bookingsDashboardCopy
  })
}

export const getOfflinePercent = ({
  reserved,
  confirmed,
  partiallyConfirmed
}: {
  reserved: GTBookingsDashboardDataMethodInfo
  confirmed: GTBookingsDashboardDataMethodInfo
  partiallyConfirmed: GTBookingsDashboardDataMethodInfo
}): number => {
  const total = getTotalDashboard({ reserved, confirmed, partiallyConfirmed })
  const totalCount = total.online.count + total.offline.count

  if (totalCount === 0) return 0

  return total.offline.count / totalCount
}

export const getOnlinePercent = ({
  reserved,
  confirmed,
  partiallyConfirmed
}: {
  reserved: GTBookingsDashboardDataMethodInfo
  confirmed: GTBookingsDashboardDataMethodInfo
  partiallyConfirmed: GTBookingsDashboardDataMethodInfo
}): number => {
  const total = getTotalDashboard({ reserved, confirmed, partiallyConfirmed })
  const totalCount = total.online.count + total.offline.count

  if (totalCount === 0) return 0

  return total.online.count / totalCount
}

export const canApplyInsurance = (booking: GTBooking): boolean => {
  const ticketWithoutInsurance = booking.tickets.find((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }): TSFixMe => type === ANCILLARY_TYPE.INSURANCE)
      return !insurance || canApplyInsuranceForTicket(insurance.status, insurance.paid)
    }

    return false
  })

  return !isEmpty(ticketWithoutInsurance)
}

export const canCancelInsurance = (booking: GTBooking): boolean => {
  const ticketWithInsurance = booking.tickets.find((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }): TSFixMe => type === ANCILLARY_TYPE.INSURANCE)
      return insurance && canCancelInsuranceForTicket(insurance.status)
    }

    return false
  })

  return !isEmpty(ticketWithInsurance)
}

export const getTotalTicketPayment = ({
  subtotal,
  vat,
  discount = 0
}: {
  subtotal: number
  vat: number
  discount: number
}): number => {
  return subtotal + vat - discount
}

const BOOKING_REFUNDS_FRAGMENT = gql`
  fragment BookingRefunds on Booking {
    refunds {
      paymentID
      amount
    }
  }
`
export const resolvers = {
  Mutation: {
    updateBookingStatus(
      _root: never,
      { status, bookingId }: { status: string; bookingId: string },
      { cache }: { cache: InMemoryCache }
    ): null {
      cache.writeFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingStatus on Booking {
            status
          }
        `,
        data: {
          __typename: 'Booking',
          status
        }
      })

      return null
    },
    addRefundsToBooking(
      _root: never,
      { bookingId, refunds }: { bookingId: string; refunds: { paymentId: string; amount: number }[] },
      { cache }: { cache: InMemoryCache }
    ): null {
      const data = cache.readFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: BOOKING_REFUNDS_FRAGMENT
      }) as { refunds: GTBookingRefund[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: BOOKING_REFUNDS_FRAGMENT,
        data: {
          __typename: 'Booking',
          refunds: [
            ...data.refunds,
            ...refunds.map((refund): TSFixMe => {
              return {
                paymentID: refund.paymentId,
                amount: refund.amount
              }
            })
          ]
        }
      })

      return null
    },
    updateTicketStatus(
      _root: never,
      { status, ticketId }: { status: string; ticketId: string },
      { cache }: { cache: InMemoryCache }
    ): null {
      cache.writeFragment({
        id: cache.identify({
          __typename: 'BookingTicket',
          id: ticketId
        }),
        fragment: gql`
          fragment TicketStatus on BookingTicket {
            status
          }
        `,
        data: {
          __typename: 'BookingTicket',
          status
        }
      })

      return null
    },
    updateBookingPayment(
      _root: never,
      {
        chargeId,
        last4,
        bookingId,
        method,
        processor,
        tickets = []
      }: {
        chargeId: string
        last4: string
        bookingId: string
        method: string
        processor: string
        tickets: GTPaymentTicket[]
      },
      { cache }: { cache: InMemoryCache }
    ): null {
      const newPayment = {
        __typename: 'BookingPayment',
        id: getRandomId(),
        method,
        processor,
        status: 'paid',
        chargeID: chargeId || null,
        last4: last4 || null,
        authCode: null,
        tickets: tickets.map((ticket): TSFixMe => {
          return {
            __typename: 'PaymentTicket',
            id: ticket.id,
            subtotal: ticket.subtotal,
            vat: ticket.vat,
            discount: ticket.discount
          }
        })
      }

      const { payments } = cache.readFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingPayment on Booking {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `
      }) as { payments: GTBookingPayment[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingPayment on Booking {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `,
        data: {
          __typename: 'Booking',
          status: STATUS.CONFIRMED,
          payments: [...payments, newPayment]
        }
      })

      return null
    },
    updateBookingPaymentMethod(
      _root: never,
      { bookingId, method, tickets }: { bookingId: string; method: string; tickets: GTPaymentTicket[] },
      { cache }: { cache: InMemoryCache }
    ): null {
      const newPayment = {
        __typename: 'BookingPayment',
        id: getRandomId(),
        method,
        processor: null,
        status: 'paid',
        chargeID: null,
        authCode: null,
        last4: null,
        tickets: tickets.map((ticket: GTPaymentTicket): TSFixMe => {
          return {
            __typename: 'PaymentTicket',
            id: ticket.id,
            subtotal: ticket.subtotal,
            vat: ticket.vat,
            discount: ticket.discount
          }
        })
      }

      const { payments } = cache.readFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingPayment on Booking {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `
      }) as { payments: GTBookingPayment[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingPayment on Booking {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `,
        data: {
          __typename: 'Booking',
          status: STATUS.CONFIRMED,
          payments: [...payments, newPayment]
        }
      })
      return null
    },
    addBookingSupportInteraction(
      _root: never,
      {
        bookingId,
        username,
        callStatus,
        notificationChannel,
        notes
      }: { bookingId: string; username: string; callStatus: string; notificationChannel: string; notes: string[] },
      { cache }: { cache: InMemoryCache }
    ): null {
      const newSupportInteraction = {
        __typename: 'SupportInteraction',
        createdAt: new Date(),
        user: {
          __typename: 'InteractionUser',
          username
        },
        callStatus,
        notificationChannel,
        notes
      }

      const { supportInteractions } = cache.readFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingSupportInteractions on Booking {
            supportInteractions {
              callStatus
              createdAt
              notes
              notificationChannel
              user {
                username
              }
            }
          }
        `
      }) as { supportInteractions: GTSupportInteraction[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'Booking',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingSupportInteractions on Booking {
            supportInteractions {
              callStatus
              createdAt
              notes
              notificationChannel
              user {
                username
              }
            }
          }
        `,
        data: {
          __typename: 'Booking',
          supportInteractions: [...supportInteractions, newSupportInteraction]
        }
      })

      return null
    }
  }
}
