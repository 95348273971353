import { gql } from '@apollo/client'

const GET_URLS = gql`
  query GetUrls {
    urls @client {
      last
    }
  }
`

export const resolvers = {
  Mutation: {
    setLastUrl(_root: TSFixMe, { url }: TSFixMe, { cache }: TSFixMe) {
      const { urls } = cache.readQuery({ query: GET_URLS })

      cache.writeQuery({
        query: GET_URLS,
        data: {
          urls: {
            ...urls,
            last: url
          }
        }
      })
    }
  }
}
