/**
 * Enum for feature flags names.
 * @property {string} DayFrecuencyKreditsSettings - This flag is used for the 'Day Frequency' option
 * in the Kredit Settings form. It is used for testing and QA purposes.
 */
export enum EFeatureFlagsNames {
  DayFrecuencyKreditsSettings = 'day-frecuency-kredits-settings'
}

export const featureFlagsValues = [
  {
    name: EFeatureFlagsNames.DayFrecuencyKreditsSettings,
    envValue: process.env.DAY_FRECUENCY_KREDITS_SETTINGS === 'true'
  }
]
