import { ReactElement, useState, useEffect } from 'react'
import styled from 'styled-components'

import Modal from 'components/v2/Modal'
import { EFeatureFlagsNames } from 'config/feature-flags'
import { useFeatureFlag } from '@10xlabs/hooks'
import { Switch } from 'components/v2/Switch'

const Title = styled.h3`
  font-size: 18px;
  color: #1d3e72;
  margin: 0;
`

const List = styled.ul`
  list-style: none;
  margin: -15px 0;
  padding: 0;
`

const Item = styled.li`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ItemLabel = styled.span`
  font-size: 15px;
  color: #1d3e72;
`

enum EAcceptedKeys {
  SHIFT_KEY = 'Shift',
  F_KEY = 'F'
}
const ACCEPTED_KEYS = [EAcceptedKeys.SHIFT_KEY, EAcceptedKeys.F_KEY]

export const FeatureFlagsModal = (): ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false)

  const [dayFrecuencyFeatureFlagValue, setDayFrecuencyFeatureFlagValue] = useFeatureFlag(
    EFeatureFlagsNames.DayFrecuencyKreditsSettings
  )
  const handleClose = (): void => {
    setIsOpen(false)
  }

  const keysPressed = {
    [EAcceptedKeys.SHIFT_KEY]: false,
    [EAcceptedKeys.F_KEY]: false
  }

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent): void => {
      if ((ACCEPTED_KEYS as string[]).includes(event.key)) {
        keysPressed[event.key as EAcceptedKeys] = true
      }
      if (keysPressed[EAcceptedKeys.SHIFT_KEY] && keysPressed[EAcceptedKeys.F_KEY]) {
        setIsOpen(true)
      }
    }
    document.addEventListener('keydown', keydownListener)

    const keyupListener = (event: KeyboardEvent): void => {
      if ((ACCEPTED_KEYS as string[]).includes(event.key)) {
        keysPressed[event.key as EAcceptedKeys] = false
      }
    }
    document.addEventListener('keyup', keyupListener)

    return (): void => {
      document.removeEventListener('keydown', keydownListener)
      document.removeEventListener('keyup', keyupListener)
    }
  }, [])

  if (!isOpen) return null

  return (
    <Modal.Container small>
      <Modal.Header onClose={handleClose}>
        <Title>Feature flags configuration</Title>
      </Modal.Header>
      <Modal.Content>
        <List>
          <Item>
            <ItemLabel>Show day frecuency - Kredit Settings</ItemLabel>
            <Switch
              value={dayFrecuencyFeatureFlagValue}
              onChange={(value: boolean): void => {
                setDayFrecuencyFeatureFlagValue(value)
                window.location.reload()
              }}
            />
          </Item>
        </List>
      </Modal.Content>
    </Modal.Container>
  )
}
